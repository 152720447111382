export function AlertListDetailsExpand({ showDetails, alert, admin, onSave }) {
  return null;
}
// import {
//   Button,
//   Col,
//   List as AntList,
//   Row,
//   Grid,
//   Input,
//   Select,
//   Form,
// } from "antd";
// import { styled } from "styled-components";
// import { useEffect, useRef, useState } from "react";
// import { useApiTracker } from "../hooks/use-api-traker";
// import { AlertImage } from "./alert_image";
// import backendApi from "../api/backend";
// import { config } from "../conf";
//
// const { useBreakpoint } = Grid;
//
// const DetailsListItem = styled(ListItem)`
//   transition: all 0.3s ease-out;
// `;
//
// function convertToDms(dd, isLng) {
//   var dir = dd < 0 ? (isLng ? "W" : "S") : isLng ? "E" : "N";
//
//   var absDd = Math.abs(dd);
//   var deg = absDd | 0;
//   var frac = absDd - deg;
//   var min = (frac * 60) | 0;
//   var sec = frac * 3600 - min * 60;
//   // Round it to 2 decimal points.
//   sec = Math.round(sec * 100) / 100;
//   return deg + "°" + min + "'" + sec + '"' + dir;
// }
//
// export function ListItem({ children, ...props }) {
//   return <AntList.Item {...props}>{children}</AntList.Item>;
// }
//
// function AlertModelInfoText({ alert }) {
//   // Round confidence scores to nearest 1000th
//   const confidenceReal =
//     Math.round(alert.modelConfidenceScore_Real * 1000) / 1000;
//   const confidenceFake =
//     Math.round(alert.modelConfidenceScore_Fake * 1000) / 1000;
//   return (
//     <>
//       <Col span={24}>
//         <b>Confidence</b>: {confidenceReal} / {confidenceFake}
//       </Col>
//     </>
//   );
// }
// function AlertGPSText({ alert }) {
//   const lat = alert?.location?.coordinates?.[1];
//   const long = alert?.location?.coordinates?.[0];
//   const latDms = convertToDms(lat);
//   const longDms = convertToDms(long);
//   let mapLink = `http://maps.google.com?daddr=${lat},${long}&amp;ll=`;
//   if (
//     navigator.platform.indexOf("iPhone") !== -1 ||
//     navigator.platform.indexOf("iPod") !== -1
//   ) {
//     function iOSversion() {
//       if (/iP(hone|od|ad)/.test(navigator.platform)) {
//         // supports iOS 2.0 and later
//         var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
//         return [
//           parseInt(v[1], 10),
//           parseInt(v[2], 10),
//           parseInt(v[3] || 0, 10),
//         ];
//       }
//     }
//     var ver = iOSversion() || [0];
//
//     var protocol = "http://";
//     if (ver[0] >= 6) {
//       protocol = "maps://";
//     }
//     mapLink = `${protocol}maps.apple.com/maps?daddr=${lat},${long}&amp;ll=`;
//   }
//   // `https://www.google.com/maps/search/?api=1&query=${lat},${long}`
//   return (
//     <>
//       <b>Lat / Long</b>:
//       <a
//         target="_blank"
//         href={mapLink}
//         style={{ paddingLeft: 10 }}
//         rel="noreferrer"
//       >
//         {latDms} / {longDms}
//       </a>
//     </>
//   );
// }
//
// // AlertListDetails
// // This displays the details of an alert in a list item.
// export function AlertListDetailsExpand({ showDetails, alert, admin, onSave }) {
//   const [form] = Form.useForm();
//   const { /* status, error, */ data, trackApi } = useApiTracker();
//   const tagListData =
//     data?.map(({ tag }) => ({ label: tag, value: tag })) || [];
//   useEffect(() => {
//     trackApi(() => backendApi.tagList());
//   }, []); // Purposely don't user trackApi
//   // Tag List Reference
//   const tagListRef = useRef();
//
//   // const [ description, setDescription ] = useState(alert.description);
//   // const [ pub, setPub ] = useState(alert.public);
//   //
//   // showSave is only used for admin users.
//   const [showSave, setShowSave] = useState(false);
//   const { xs /*, sm, md, lg, xl, xxl*/ } = useBreakpoint();
//   // reset is only used for admin users.
//   const reset = () => {
//     form.resetFields();
//     setShowSave(false);
//   };
//   // save is only used for admin users.
//   const save = async () => {
//     await onSave(alert._id, form.getFieldsValue());
//     setShowSave(false);
//   };
//   const detailStyleShow = {
//     maxHeight: "700px",
//     overflow: "hidden",
//     border: "0px",
//   };
//   const detailStyleHide = {
//     maxHeight: "0px",
//     overflow: "hidden",
//     paddingTop: "0px",
//     paddingBottom: "0px",
//     border: "0px",
//   };
//   return (
//     <DetailsListItem style={showDetails ? detailStyleShow : detailStyleHide}>
//       <Form
//         form={form}
//         name="basic"
//         initialValues={{
//           includeInPublicResults: alert.includeInPublicResults,
//           tagList: alert.tagList,
//           alertDescription: alert.alertDescription,
//         }}
//         onFinish={save}
//         autoComplete="off"
//         style={{ width: "100%" }}
//       >
//         <Row justify={"spaced-between"} style={{ width: "100%" }}>
//           {xs ? (
//             <>
//               <Col span={24}>
//                 <AlertImage alert={alert} showDetails={showDetails} />
//               </Col>
//               <Col span={24}>
//                 <Row>
//                   <AlertGPSText alert={alert} />
//                 </Row>
//                 <Row justify={"space-between"}>
//                   <AlertModelInfoText alert={alert} />
//                 </Row>
//                 <Row>
//                   <Col span={24}>
//                     <b>Model Version:</b> {alert.modelVersion}
//                   </Col>
//                 </Row>
//                 {admin && (
//                   <>
//                     <Row style={{ paddingBottom: 5 }}>
//                       <Col span={24}>
//                         <div
//                           style={{ height: 1, width: 1, margin: 0, padding: 0 }}
//                           ref={tagListRef}
//                         ></div>
//                         <Form.Item
//                           name="tagList"
//                           style={{ width: "100%", margin: 0 }}
//                         >
//                           {(tagListData?.length || 0) > 0 && (
//                             <Select
//                               mode="multiple"
//                               style={{ width: "100%" }}
//                               placeholder="Tags"
//                               onDropdownVisibleChange={(visibilty) => {
//                                 const rect =
//                                   tagListRef?.current?.getBoundingClientRect?.();
//                                 const topY = window.scrollY + rect.top;
//                                 window.scrollTo({
//                                   top: topY - 20,
//                                   behavior: "smooth",
//                                 });
//                               }}
//                               value={alert.tagList}
//                               options={tagListData}
//                               onChange={() => setShowSave(true)}
//                             />
//                           )}
//                         </Form.Item>
//                       </Col>
//                     </Row>
//                     <Row>
//                       <Col span={24}>
//                         <Form.Item
//                           name="includeInPublicResults"
//                           style={{ width: "100%", margin: 0 }}
//                         >
//                           <Select
//                             style={{ width: "11em" }}
//                             placeholder="Public (Yes/No)"
//                             value={alert.includeInPublicResults}
//                             options={[...config.edit.publicDropdown]}
//                             onChange={() => setShowSave(true)}
//                           />
//                         </Form.Item>
//                       </Col>
//                     </Row>
//                   </>
//                 )}
//                 <Row style={{ height: 10 }}></Row>
//                 <Row>
//                   <b>Description</b>:
//                 </Row>
//                 <Row>
//                   <Form.Item
//                     name="alertDescription"
//                     style={{ width: "100%", margin: 0 }}
//                   >
//                     <Input.TextArea
//                       placeholder="No description provided"
//                       onChange={() => setShowSave(true)}
//                     />
//                   </Form.Item>
//                   {/* alert.alertDescription || 'No description provided' */}
//                 </Row>
//                 {showSave && (
//                   <>
//                     <Row style={{ paddingTop: 12 }}>
//                       <Button type="primary" onClick={save}>
//                         Save Changes
//                       </Button>
//                       <Button
//                         type="default"
//                         style={{ marginLeft: 4 }}
//                         onClick={reset}
//                       >
//                         Reset
//                       </Button>
//                     </Row>
//                   </>
//                 )}
//               </Col>
//             </>
//           ) : (
//             <>
//               <Col span={19} style={{ paddingRight: 12 }}>
//                 <Row>
//                   <Col span={14}>
//                     <Row>
//                       <Col span={24}>
//                         <AlertGPSText alert={alert} />
//                       </Col>
//                     </Row>
//                     <Row justify={"space-between"}>
//                       <AlertModelInfoText alert={alert} />
//                     </Row>
//                     <Row>
//                       <Col span={24}>
//                         <b>Model Version:</b> {alert.modelVersion}
//                       </Col>
//                     </Row>
//                   </Col>
//                   {admin && (
//                     <Col span={10}>
//                       <Form.Item
//                         name="tagList"
//                         style={{ width: "100%", margin: 0 }}
//                       >
//                         {(tagListData?.length || 0) > 0 && (
//                           <Select
//                             mode="multiple"
//                             style={{ width: "100%" }}
//                             placeholder="Tags"
//                             value={alert.tagList}
//                             options={tagListData}
//                             onChange={() => setShowSave(true)}
//                           />
//                         )}
//                       </Form.Item>
//                     </Col>
//                   )}
//                 </Row>
//                 {admin && (
//                   <Row>
//                     <Col span={24}>
//                       <Form.Item
//                         name="includeInPublicResults"
//                         style={{ width: "100%", margin: 0 }}
//                       >
//                         <Select
//                           style={{ width: "11em" }}
//                           placeholder="Public (Yes/No)"
//                           options={[...config.edit.publicDropdown]}
//                           onChange={() => setShowSave(true)}
//                         />
//                       </Form.Item>
//                     </Col>
//                   </Row>
//                 )}
//                 <Row style={{ height: 10 }}></Row>
//                 <Row>
//                   <b>Description</b>:
//                 </Row>
//                 <Row>
//                   <Form.Item
//                     name="alertDescription"
//                     style={{ width: "100%", margin: 0 }}
//                   >
//                     <Input.TextArea
//                       readOnly={!admin}
//                       bordered={!!admin}
//                       style={{ padding: !admin ? 0 : undefined }}
//                       placeholder="No description provided"
//                       onChange={() => setShowSave(true)}
//                     />
//                   </Form.Item>
//                 </Row>
//                 {showSave && (
//                   <>
//                     <Row style={{ paddingTop: 12 }}>
//                       <Button type="primary" onClick={save}>
//                         Save Changes
//                       </Button>
//                       <Button
//                         type="default"
//                         style={{ marginLeft: 4 }}
//                         onClick={reset}
//                       >
//                         Reset
//                       </Button>
//                     </Row>
//                   </>
//                 )}
//               </Col>
//               <Col span={5}>
//                 <AlertImage alert={alert} showDetails={showDetails} />
//               </Col>
//             </>
//           )}
//         </Row>
//       </Form>
//     </DetailsListItem>
//   );
// }
