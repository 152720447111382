// import logo from './logo.svg';
import "antd/dist/reset.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { NotFoundPage } from "./pages/not_found";
import { AlertsPage } from "./pages/alerts";
import { AlertsAdminPage } from "./pages/alerts-admin";
import { LoginPage } from "./pages/login";
import { VerificationPage } from "./pages/verification";
import { ProtectedRoute } from "./components/protected_route";
import { ScrollToTop } from "./components/scroll_to_top";
import { UserStateProvider } from "./state/user";
import { HomePageForwarder } from "./pages/home";
import { ConfigProvider, theme } from "antd";
import { useThemeStateContext } from "./state/theme";
import { useEffect } from "react";
import { LoginPageApiKey } from "./pages/login-apikey";
import { PromptEmailPage } from "./pages/prompt-email";
import { LegalDisclaimerPage } from "./pages/legal_disclaimer";

function App() {
  const { theme: themeConfig } = useThemeStateContext();
  useEffect(() => {
    if (!!themeConfig?.isDark) {
      document.body.style.backgroundColor = "#000000";
    } else {
      document.body.style.backgroundColor = "#f5f5f5";
    }
  }, [themeConfig]);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#4ed33f",
          // colorInfo: "#4ed33f",
          colorBgHeader: !!themeConfig?.isDark ? "#424242" : "white",
        },
        algorithm: !!themeConfig?.isDark
          ? theme.darkAlgorithm
          : theme.lightAlgorithm,
      }}
    >
      <UserStateProvider>
        <BrowserRouter>
          <>
            <ScrollToTop />
            <Routes>
              <Route index element={<HomePageForwarder />} />
              <Route path="prompt-email" element={<PromptEmailPage />} />
              <Route path="login" element={<LoginPage />} />
              <Route path="login-apikey" element={<LoginPageApiKey />} />
              <Route
                path="verify/:verificationSessionId"
                element={<VerificationPage />}
              />
              <Route
                path="alerts"
                element={
                  <ProtectedRoute>
                    <AlertsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="alerts-admin"
                element={
                  <ProtectedRoute redirectForNonAdmin="/alerts">
                    <AlertsAdminPage />
                  </ProtectedRoute>
                }
              />
              <Route path="legal-disclaimer" element={<LegalDisclaimerPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </>
        </BrowserRouter>
      </UserStateProvider>
    </ConfigProvider>
  );
}

export default App;
