import { Alert, Col, Pagination, Row, Typography, Spin, Button } from "antd";
import { AlertList } from "../components/alert_list";
import { useApiTracker } from "../hooks/use-api-traker";
import { BaseLayout } from "../layouts/base";
import backendApi from "../api/backend";
import { useEffect, useState } from "react";
import { AlertListDetailsOverlay } from "../components/alert_list_details_overlay";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";

const { Title } = Typography;

export function AlertsPage() {
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [sort /* , setSort */] = useState(
    JSON.stringify({ alertTimestamp: -1 }),
  );
  const { status, error, data, trackApi } = useApiTracker();
  const [alertDetails, setAlertDetails] = useState(null);

  useEffect(() => {
    console.log("useEffect pageNumber", pageNumber);
    trackApi(() =>
      backendApi.myAlerts({
        offset: (pageNumber - 1) * pageSize,
        limit: pageSize,
        sort,
      }),
    );
  }, [pageNumber, pageSize, sort]);

  const onDetailsClick = (alertId, value) => {
    console.log(
      "onDetailsClick",
      data?.alerts?.find((alert) => alert._id === alertId),
      alertId,
      value,
    );
    setAlertDetails(data?.alerts?.find((alert) => alert._id === alertId));
  };

  const onPageChange = async (page, size) => {
    console.log("onPageChange", page, size);
    setPageNumber(page);
    setPageSize(size);
  };

  const downloadCsv = async () => {
    const response = await backendApi.downloadAlerts({
      offset: undefined,
      limit: undefined,
    });

    // Create a link and click it to download the file
    // Creating a link is the only way to have a named file when
    // downloading using javascript
    let csvContent = "data:text/csv;charset=utf-8," + response.data;
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "alerts_download.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
  };

  return (
    <BaseLayout>
      {alertDetails && (
        <>
          <Title>View Alert</Title>
          {/* Add the link here */}
          <Button
            type="link"
            onClick={() => setAlertDetails(null)}
            style={{ padding: "0px", marginTop: "-16px", marginBottom: "16px" }}
          >
            Back to list
          </Button>
          <AlertListDetailsOverlay alert={alertDetails} admin={false} />
        </>
      )}
      {!alertDetails && (
        <>
          <Row justify={"center"}>
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Title>My Alerts</Title>
                <Button type="default" onClick={downloadCsv}>
                  <VerticalAlignBottomOutlined /> Download CSV
                </Button>
              </div>
              {/* Alerts list*/}
              <AlertList
                alerts={data?.alerts || []}
                isAdmin={false}
                enableDetailsExpand={false}
                onDetailsClick={onDetailsClick}
              />
              {/* Show error if HTTP error occures */}
              {error ? (
                <Alert
                  style={{ marginBottom: 24 }}
                  type="error"
                  message={"Error loading alerts. Try again later"}
                  banner
                />
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row justify={"center"} style={{ paddingTop: 4 }}>
            <Col span={23}>
              <Pagination
                defaultCurrent={1}
                current={pageNumber}
                total={data?.alertsTotal}
                pageSize={pageSize}
                onChange={onPageChange}
              />
            </Col>
            <Col span={1}>{status === "loading" && <Spin />}</Col>
          </Row>
        </>
      )}
    </BaseLayout>
  );
}
