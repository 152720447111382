import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Button, Col, List as AntList, Row, Grid } from "antd";
import { DateTime } from "luxon";
import { useState } from "react";
import { styled } from "styled-components";
import { config } from "../conf";
import { AlertListDetailsExpand } from "./alert_list_detail_expand";

const { useBreakpoint } = Grid;

const ClickableListItem = styled(ListItem)`
  &:hover {
    cursor: pointer;
  }
`;

const styleMap = {
  fake: { backgroundColor: config.colors.rowFakeBg },
  real: { backgroundColor: config.colors.rowRealBg },
  inconclusive: { backgroundColor: config.colors.rowInconclusiveBg },
};

const ClickableCol = styled(Col)`
  cursor: pointer;
`;

export function List({ children, ...props }) {
  return (
    <AntList {...props} bordered>
      {children}
    </AntList>
  );
}

export function ListHeader({ children, style, ...props }) {
  return (
    <AntList.Item
      {...props}
      style={{ ...style, backgroundColor: "grey", fontWeight: "bold" }}
    >
      {children}
    </AntList.Item>
  );
}

export function ListItem({ children, ...props }) {
  return <AntList.Item {...props}>{children}</AntList.Item>;
}

export function ShowDetailsButton({ showDetails, setShowDetails }) {
  const { /* xs , sm,  */ md, lg, xl, xxl } = useBreakpoint();
  const isSmall = !xxl && !xl && !lg; // && !md;
  const showText = isSmall
    ? config.text.list.details.showSmall
    : config.text.list.details.show;
  const hideText = isSmall
    ? config.text.list.details.hideSmall
    : config.text.list.details.hide;
  return (
    <Button type="link" onClick={() => setShowDetails(!showDetails)}>
      {!showDetails ? showText : hideText}
    </Button>
  );
}

export function AlertListItemAdmin({
  alert,
  showDetails,
  onShowDetails,
  onSave,
}) {
  const { /* xs , sm, */ md, lg, xl, xxl } = useBreakpoint();
  const [alertUpdateOverride, setAlertUpdateOverride] = useState({});
  const isSmall = !xxl && !xl && !lg && !md;
  const style =
    styleMap[alert.modelMatchesCategory] || styleMap["inconclusive"];
  const alertDate = DateTime.fromSeconds(alert.alertTimestamp)
    .toLocal()
    .toFormat("MM/dd/yy");
  const alertTime = DateTime.fromSeconds(alert.alertTimestamp).toLocaleString(
    DateTime.TIME_SIMPLE,
  );
  const alertTimestamp = (
    <>
      {alertDate}
      {isSmall ? <br /> : " "}
      {alertTime}
    </>
  );
  // State for showing details
  // const [showDetails, setShowDetails] = useState(false);
  const setShowDetails = () => {
    onShowDetails(alert._id, !showDetails);
  };
  // Col Widths
  const colWidths = config.colWidths.adminList[isSmall ? "small" : "default"];
  // Category Label
  const categoryLabel = config.text.list.category[alert.modelMatchesCategory];
  const onAlertSave = async (id, values) => {
    await onSave(id, values);
    setAlertUpdateOverride({
      includeInPublicResults: values.includeInPublicResults,
    });
  };
  // Count of alert.tagList that match the review filter config.detailsEdit.reviewFilterForCountRegex
  const reviewCount = (
    alert.tagList?.filter((tag) =>
      new RegExp(config.detailsEdit.reviewFilterForCountRegex).test(tag),
    ) || []
  ).length;
  // Admin View
  return (
    <>
      <ClickableListItem
        style={{
          ...style,
          ...{
            overflow: "hidden",
            padding: 4,
            paddingLeft: 8,
            paddingRight: 8,
          },
        }}
        onClick={setShowDetails}
      >
        <Col span={24}>
          {/* Main info for admins */}
          <Row>
            <Col span={colWidths.category}>{categoryLabel}</Col>
            <Col span={colWidths.alertTimestamp}>{alertTimestamp}</Col>
            {/* Wrap should work better and as such maybe overflow hidden is not needed*/}
            <Col span={colWidths.nearestCity}>
              {alert.nearestCity || "Unknown"}
            </Col>
            {/* Since it is all on a single line overlflow = hidden is really needed */}
            <Col
              span={colWidths.alertUser}
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {alert.alertUser || "Unknown"}
            </Col>
            <Col
              span={colWidths.includeInPublicResults}
              style={{ textAlign: "center" }}
            >
              {(alertUpdateOverride.includeInPublicResults ??
              alert.includeInPublicResults)
                ? "Y"
                : "N"}
            </Col>
            <Col span={colWidths.reviewCount}>{reviewCount}</Col>
            {colWidths?.showDetails && (
              <Col span={colWidths?.showDetails} style={{ textAlign: "right" }}>
                <ShowDetailsButton
                  showDetails={showDetails}
                  setShowDetails={setShowDetails}
                />
              </Col>
            )}
          </Row>
        </Col>
      </ClickableListItem>
      {
        <AlertListDetailsExpand
          showDetails={showDetails}
          alert={alert}
          admin={true}
          onSave={onAlertSave}
        />
      }
    </>
  );
}

export function AlertListItemMine({
  alert,
  showDetails,
  onShowDetails,
  onSave,
}) {
  const { /* xs , sm, */ md, lg, xl, xxl } = useBreakpoint();
  const isSmall = !xxl && !xl && !lg && !md;
  const style =
    styleMap[alert.modelMatchesCategory] || styleMap["inconclusive"];
  const alertDate = DateTime.fromSeconds(alert.alertTimestamp).toLocaleString(
    DateTime.DATE_SHORT,
  );
  const alertTime = DateTime.fromSeconds(alert.alertTimestamp).toLocaleString(
    DateTime.TIME_SIMPLE,
  );
  const alertTimestamp = (
    <>
      {alertDate}
      {isSmall ? <br /> : " "}
      {alertTime}
    </>
  );
  // Col Widths
  const colWidths =
    config.colWidths.nonAdminList[isSmall ? "small" : "default"];
  // State for showing details
  // const [showDetails, setShowDetails] = useState(false);
  const setShowDetails = () => {
    onShowDetails(alert._id, !showDetails);
  };
  // Category Label
  const categoryLabel = config.text.list.category[alert.modelMatchesCategory];
  // Non Admin View
  return (
    <>
      <ClickableListItem
        style={{
          ...style,
          ...{
            overflow: "hidden",
            padding: 4,
            paddingLeft: 8,
            paddingRight: 8,
          },
        }}
        onClick={setShowDetails}
      >
        <Col span={24}>
          {/* Main info for non-admoins */}
          <Row>
            <Col span={colWidths.category}>{categoryLabel}</Col>
            <Col span={colWidths.alertTimestamp}>{alertTimestamp}</Col>
            <Col span={colWidths.nearestCity}>
              {alert.nearestCity || "Unknown"}
            </Col>
            {colWidths?.showDetails && (
              <Col span={colWidths?.showDetails} style={{ textAlign: "right" }}>
                <ShowDetailsButton
                  showDetails={showDetails}
                  setShowDetails={setShowDetails}
                />
              </Col>
            )}
          </Row>
        </Col>
      </ClickableListItem>
      {/* Show details for non-admins */}
      <AlertListDetailsExpand
        showDetails={showDetails}
        alert={alert}
        onSave={onSave}
      />
    </>
  );
}

/**
 * @function AlertListHeaderSortableCol
 * @description Renders header for the alert list for non-admins
 * @returns {JSX.Element}
 **/
export function AlertListHeaderSortableCol({
  span,
  handleSortChange,
  field,
  sort,
  order,
}) {
  const breakpoints = useBreakpoint();
  const ColType = handleSortChange ? ClickableCol : Col;
  return (
    <ColType
      span={span}
      onClick={() => !handleSortChange || handleSortChange(field)}
    >
      {typeof config.text.header[field] === "function"
        ? config.text.header[field](breakpoints)
        : config.text.header[field]}
      {handleSortChange && sort === field && order === "asc" && (
        <ArrowUpOutlined />
      )}
      {handleSortChange && sort === field && order === "desc" && (
        <ArrowDownOutlined />
      )}
    </ColType>
  );
}

/**
 * @function AlertListHeaderAdmin
 * @description Renders header for the alert list for admins
 * @returns {JSX.Element}
 * */
export function AlertListHeaderAdmin({ onSortChange }) {
  const { /* xs , sm, */ md, lg, xl, xxl } = useBreakpoint();
  const isSmall = !xxl && !xl && !lg && !md;
  // Col Widths
  const colWidths = config.colWidths.adminList[isSmall ? "small" : "default"];

  const [sort, setSort] = useState("alertTimestamp");
  const [order, setOrder] = useState("desc");
  const handleSortChange = onSortChange
    ? (newSort) => {
        const newOrder =
          newSort !== sort ? "desc" : order === "desc" ? "asc" : "desc";
        setSort(newSort);
        setOrder(newOrder);
        onSortChange(newSort, newOrder);
      }
    : undefined;

  return (
    <Col span={24}>
      <Row>
        <AlertListHeaderSortableCol
          span={colWidths.category}
          handleSortChange={handleSortChange}
          field={"modelMatchesCategory"}
          sort={sort}
          order={order}
        />
        <AlertListHeaderSortableCol
          span={colWidths.alertTimestamp}
          handleSortChange={handleSortChange}
          field={"alertTimestamp"}
          sort={sort}
          order={order}
        />
        <AlertListHeaderSortableCol
          span={colWidths.nearestCity}
          handleSortChange={handleSortChange}
          field={"nearestCity"}
          sort={sort}
          order={order}
        />
        <AlertListHeaderSortableCol
          span={colWidths.alertUser}
          handleSortChange={handleSortChange}
          field={"alertUser"}
          sort={sort}
          order={order}
        />
        <AlertListHeaderSortableCol
          span={colWidths.includeInPublicResults}
          handleSortChange={handleSortChange}
          field={"includeInPublicResults"}
          sort={sort}
          order={order}
        />
        <AlertListHeaderSortableCol
          span={colWidths.reviewCount}
          field={"reviewCount"}
          sort={sort}
          order={order}
        />
        {colWidths.showDetails && <Col span={colWidths.showDetails}></Col>}
      </Row>
    </Col>
  );
}

/**
 * @function AlertListHeaderMine
 * @description Renders header for the alert list for non-admins
 * @returns {JSX.Element}
 * */
export function AlertListHeaderMine({ onSortChange }) {
  const [sort, setSort] = useState("alertTimestamp");
  const [order, setOrder] = useState("desc");
  const { /* xs , sm, */ md, lg, xl, xxl } = useBreakpoint();
  const isSmall = !xxl && !xl && !lg && !md;
  // Col Widths
  const colWidths =
    config.colWidths.nonAdminList[isSmall ? "small" : "default"];

  const handleSortChange = onSortChange
    ? (newSort) => {
        const newOrder =
          newSort !== sort ? "desc" : order === "desc" ? "asc" : "desc";
        setSort(newSort);
        setOrder(newOrder);
        onSortChange(sort, order);
      }
    : undefined;

  return (
    <Col span={24}>
      <Row>
        <AlertListHeaderSortableCol
          span={colWidths.category}
          handleSortChange={handleSortChange}
          field={"modelMatchesCategory"}
          sort={sort}
          order={order}
        />
        <AlertListHeaderSortableCol
          span={colWidths.alertTimestamp}
          handleSortChange={handleSortChange}
          field={"alertTimestamp"}
          sort={sort}
          order={order}
        />
        <AlertListHeaderSortableCol
          span={colWidths.nearestCity}
          handleSortChange={handleSortChange}
          field={"nearestCity"}
          sort={sort}
          order={order}
        />
        {colWidths.showDetails && <Col span={colWidths.showDetails}></Col>}
      </Row>
    </Col>
  );
}

/**
 * @function AlertList
 * @description Renders a list of alerts
 *
 * @param {Object} props
 * @param {Array} props.alerts: List of alerts
 * @param {Boolean} props.isAdmin: For admin view
 * @returns {JSX.Element}
 * */
export function AlertList({
  alerts,
  isAdmin,
  onSortChange,
  onSave,
  onDetailsClick,
  enableDetailsExpand,
}) {
  const [showDetails, setShowDetails] = useState({});

  const onShowDetails = (alertId, val) => {
    // Call onDetailsClick if provided
    if (onDetailsClick) {
      onDetailsClick(alertId, val);
    }
    // If details expand is enabled, set the state
    if (enableDetailsExpand) {
      setShowDetails({ [alertId]: val });
    }
  };

  return (
    <List>
      <ListHeader style={{ paddingLeft: 8, paddingRight: 8 }}>
        {/* Admin Header */}
        {isAdmin && <AlertListHeaderAdmin onSortChange={onSortChange} />}
        {/* Non-Admin Header */}
        {!isAdmin && <AlertListHeaderMine onSortChange={onSortChange} />}
      </ListHeader>
      {alerts.map((alert) => {
        if (isAdmin) {
          return (
            <AlertListItemAdmin
              alert={alert}
              key={alert._id}
              showDetails={showDetails[alert._id] || false}
              onShowDetails={onShowDetails}
              onSave={onSave}
            />
          );
        }
        return (
          <AlertListItemMine
            alert={alert}
            key={alert._id}
            showDetails={showDetails[alert._id] || false}
            onShowDetails={onShowDetails}
            onSave={onSave}
          />
        );
      })}
    </List>
  );
}
