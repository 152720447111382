import { Button, Col, Row, Grid, Input, Form } from "antd";
import { useEffect, useState } from "react";
import { useApiTracker } from "../hooks/use-api-traker";
import { AlertImage } from "./alert_image";
import backendApi from "../api/backend";
import { TagsSelect } from "./tags_select";

const { useBreakpoint } = Grid;

function convertToDms(dd, isLng) {
  var dir = dd < 0 ? (isLng ? "W" : "S") : isLng ? "E" : "N";
  var absDd = Math.abs(dd);
  var deg = absDd | 0;
  var frac = absDd - deg;
  var min = (frac * 60) | 0;
  var sec = frac * 3600 - min * 60;
  sec = Math.round(sec * 100) / 100;
  return deg + "°" + min + "'" + sec + '"' + dir;
}

function AlertModelInfoText({ alert }) {
  const confidenceReal =
    Math.round(alert.modelConfidenceScore_Real * 1000) / 1000;
  const confidenceFake =
    Math.round(alert.modelConfidenceScore_Fake * 1000) / 1000;
  return (
    <Col span={24}>
      <b>Confidence</b>: {confidenceReal} / {confidenceFake}
    </Col>
  );
}

function AlertGPSText({ alert }) {
  const lat = alert?.location?.coordinates?.[1];
  const long = alert?.location?.coordinates?.[0];
  const latDms = convertToDms(lat);
  const longDms = convertToDms(long);
  let mapLink = `http://maps.google.com?daddr=${lat},${long}&amp;ll=`;
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    const iOSversion = () => {
      const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
    };
    const ver = iOSversion() || [0];
    const protocol = ver[0] >= 6 ? "maps://" : "http://";
    mapLink = `${protocol}maps.apple.com/maps?daddr=${lat},${long}&amp;ll=`;
  }
  return (
    <>
      <b>Lat / Long</b>:
      <a
        target="_blank"
        href={mapLink}
        style={{ paddingLeft: 10 }}
        rel="noreferrer"
      >
        {latDms} / {longDms}
      </a>
    </>
  );
}

function AdminMenu({
  form,
  alert,
  tagListData,
  selected,
  setSelected,
  save,
  showSave,
  setShowSave,
  reset,
}) {
  const screens = useBreakpoint();

  if (screens.md) {
    // Two-column layout for wider screens
    return (
      <>
        <Col xs={24} md={12} style={{ padding: "20px" }}>
          <Form
            form={form}
            name="basic"
            initialValues={{
              includeInPublicResults: alert.includeInPublicResults,
              tagList: alert.tagList,
              alertDescription: alert.alertDescription,
            }}
            onFinish={save}
            autoComplete="off"
            style={{ width: "100%" }}
          >
            <Row justify={"spaced-between"} style={{ width: "100%" }}>
              <Col span={24}>
                <AlertImage alert={alert} showDetails={true} />
              </Col>
              <Col span={24}>
                <Row>
                  <AlertGPSText alert={alert} />
                </Row>
                <Row justify={"space-between"}>
                  <AlertModelInfoText alert={alert} />
                </Row>
                <Row>
                  <Col span={24}>
                    <b>Model Version:</b> {alert.modelVersion}
                  </Col>
                </Row>
                <Row style={{ height: 10 }}></Row>
                <Row>
                  <b>Description</b>:
                </Row>
                <Row>
                  <Form.Item
                    name="alertDescription"
                    style={{ width: "100%", margin: 0 }}
                  >
                    <Input.TextArea
                      readOnly={false}
                      bordered={true}
                      placeholder="No description provided"
                      onChange={() => setShowSave(true)}
                    />
                  </Form.Item>
                </Row>
                {showSave && (
                  <Row style={{ paddingTop: 12 }}>
                    <Button type="primary" onClick={save}>
                      Save Changes
                    </Button>
                    <Button
                      type="default"
                      style={{ marginLeft: 4 }}
                      onClick={reset}
                    >
                      Reset
                    </Button>
                  </Row>
                )}
              </Col>
            </Row>
          </Form>
        </Col>
        <Col
          xs={24}
          md={12}
          style={{ padding: "20px", backgroundColor: "#f0f0f0" }}
        >
          <Form
            form={form}
            initialValues={{
              tagList: alert.tagList,
            }}
          >
            <h3>Tags</h3>
            <Form.Item name="tagList" style={{ width: "100%", margin: 0 }}>
              <TagsSelect
                selectedTags={selected}
                allTags={tagListData}
                onChange={(tags) => {
                  setSelected(tags);
                  setShowSave(true);
                }}
                xs={24}
                sm={24}
                md={24}
                lg={12}
              />
            </Form.Item>
          </Form>
        </Col>
      </>
    );
  } else {
    // Single-column layout for small screens:
    return (
      <Col xs={24} style={{ padding: "20px" }}>
        <Form
          form={form}
          name="basic"
          initialValues={{
            includeInPublicResults: alert.includeInPublicResults,
            tagList: alert.tagList,
            alertDescription: alert.alertDescription,
          }}
          onFinish={save}
          autoComplete="off"
          style={{ width: "100%" }}
        >
          <Row style={{ marginBottom: 20 }}>
            <AlertImage alert={alert} showDetails={true} />
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <h3>Tags</h3>
            <Form.Item name="tagList" style={{ width: "100%", margin: 0 }}>
              <TagsSelect
                selectedTags={selected}
                allTags={tagListData}
                onChange={(tags) => {
                  setSelected(tags);
                  setShowSave(true);
                }}
                xs={24}
              />
            </Form.Item>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <AlertGPSText alert={alert} />
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <AlertModelInfoText alert={alert} />
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col span={24}>
              <b>Model Version:</b> {alert.modelVersion}
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <b>Description</b>:
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Form.Item
              name="alertDescription"
              style={{ width: "100%", margin: 0 }}
            >
              <Input.TextArea
                readOnly={false}
                bordered={true}
                placeholder="No description provided"
                onChange={() => setShowSave(true)}
              />
            </Form.Item>
          </Row>
          {showSave && (
            <Row style={{ paddingTop: 12 }}>
              <Button type="primary" onClick={save}>
                Save Changes
              </Button>
              <Button type="default" style={{ marginLeft: 4 }} onClick={reset}>
                Reset
              </Button>
            </Row>
          )}
        </Form>
      </Col>
    );
  }
}

export { AdminMenu };

function NonAdminMenu({ form, alert, save, showSave, setShowSave, reset }) {
  return (
    <>
      <Col span={18}>
        <Form
          form={form}
          name="basic"
          initialValues={{
            includeInPublicResults: alert.includeInPublicResults,
            tagList: alert.tagList,
            alertDescription: alert.alertDescription,
          }}
          onFinish={save}
          autoComplete="off"
          style={{ width: "100%" }}
        >
          <Row justify={"spaced-between"} style={{ width: "100%" }}>
            <Col span={24}>
              <Row>
                <AlertGPSText alert={alert} />
              </Row>
              <Row justify={"space-between"}>
                <AlertModelInfoText alert={alert} />
              </Row>
              <Row>
                <Col span={24}>
                  <b>Model Version:</b> {alert.modelVersion}
                </Col>
              </Row>
              <Row style={{ height: 10 }}></Row>
              <Row>
                <b>Description</b>:
              </Row>
              <Row>
                <Form.Item
                  name="alertDescription"
                  style={{ width: "100%", margin: 0 }}
                >
                  <Input.TextArea
                    readOnly={true}
                    bordered={false}
                    style={{ padding: 0 }}
                    placeholder="No description provided"
                  />
                </Form.Item>
              </Row>
              {showSave && (
                <>
                  <Row style={{ paddingTop: 12 }}>
                    <Button type="primary" onClick={save}>
                      Save Changes
                    </Button>
                    <Button
                      type="default"
                      style={{ marginLeft: 4 }}
                      onClick={reset}
                    >
                      Reset
                    </Button>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Form>
      </Col>
      <Col span={6}>
        <AlertImage alert={alert} showDetails={true} />
      </Col>
    </>
  );
}

export function AlertListDetailsOverlay({ alert, admin, onSave }) {
  const [form] = Form.useForm();
  const { data, trackApi } = useApiTracker();
  const tagListData = data?.map(({ tag }) => tag) || [];
  useEffect(() => {
    trackApi(() => backendApi.tagList());
  }, [trackApi]);
  const [showSave, setShowSave] = useState(false);
  const [selected, setSelected] = useState(alert.tagList);

  const reset = () => {
    form.resetFields();
    setSelected(alert.tagList); // Reset the selected tags
    setShowSave(false);
  };

  const save = async () => {
    await onSave(alert._id, { ...form.getFieldsValue(), tagList: selected });
    setShowSave(false);
  };

  return (
    <Row style={{ width: "100%" }}>
      {admin ? (
        <AdminMenu
          form={form}
          alert={alert}
          tagListData={tagListData}
          selected={selected}
          setSelected={setSelected}
          save={save}
          showSave={showSave}
          setShowSave={setShowSave}
          reset={reset}
        />
      ) : (
        <NonAdminMenu
          form={form}
          alert={alert}
          save={save}
          showSave={showSave}
          setShowSave={setShowSave}
          reset={reset}
        />
      )}
    </Row>
  );
}
