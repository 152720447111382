import {
  Button,
  Checkbox,
  Form,
  Input,
  Alert,
  Card,
  Row,
  Col,
  Spin,
} from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { BaseLayout } from "../layouts/base";
import { useUserStateContext } from "../state/user";
import { useApiTracker } from "../hooks/use-api-traker";
import backendApi from "../api/backend";
import { useRef } from "react";

export function LoginPageApiKey() {
  const navigate = useNavigate();
  const location = useLocation();
  // Parse query parameter "showForm"
  const queryParams = new URLSearchParams(location.search);
  const showForm = queryParams.get("showForm") !== "true";

  const { setUser } = useUserStateContext();
  const apiKeyRef = useRef(null);
  const rememberRef = useRef(null);
  const { status, error, trackApi } = useApiTracker();

  const onFinish = async (values) => {
    // const storedUser = {
    //   remember: rememberRef.current.input.value,
    //   apiKey: apiKeyRef.current.input.value,
    //   isLoggedIn: true,
    // };
    const storedUser = {
      remember: rememberRef.current.input.value, //values.remember,
      apiKey: apiKeyRef.current.input.value, //values.apiKey,
      isLoggedIn: true,
    };
    trackApi(async () => {
      backendApi.setApiKey(storedUser.apiKey);
      try {
        const response = await backendApi.userInfo();
        console.log("User is admin", response?.data?.isAdmin);

        const deviceId =
          response?.data?.authentication?.deviceId ||
          localStorage.getItem("deviceId") ||
          undefined;
        localStorage.setItem("deviceId", deviceId);

        let newUser = {
          ...storedUser,
          ...response.data.authentication,
          userIdentifier: response.data.user,
          isAdmin: response.data.isAdmin,
          deviceId: deviceId,
        };

        setUser(newUser);
        navigate("/");
      } catch (err) {
        if (err.response && err.response.status === 401) {
          throw new Error(
            "We were unable to login to due to an authentication error. If this problem continues, you may have to logout of the app",
          );
        }
        throw err;
      }
    });
  };

  return (
    <BaseLayout>
      <Row justify="center">
        <Col span={24}>
          <Card
            style={{
              width: "384px",
              margin: "auto",
              borderRadius: 40,
              padding: "24px",
            }}
          >
            <Form
              name="basic"
              style={{ display: showForm ? "none" : "block" }}
              onFinish={onFinish}
              initialValues={{
                remember: true,
                isAdmin: false,
              }}
            >
              <Form.Item name="apiKey" label="API Key">
                <Input ref={apiKeyRef} placeholder="Enter API key" />
              </Form.Item>
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox ref={rememberRef}>Remember me</Checkbox>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={status === "loading"}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>

            {error && (
              <Form.Item>
                <Alert
                  type="error"
                  message="Error logging in"
                  description={error}
                />
              </Form.Item>
            )}

            {showForm && !error && (
              <Spin
                tip="loading..."
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "200px",
                }}
              />
            )}
          </Card>
        </Col>
      </Row>
    </BaseLayout>
  );
}
// import {
//   Button,
//   Checkbox,
//   Form,
//   Input,
//   Alert,
//   Card,
//   Row,
//   Col,
//   Typography,
// } from "antd";
// import { useNavigate } from "react-router-dom";
// import { BaseLayout } from "../layouts/base";
// import {
//   refreshUserInformationFromApiKey,
//   useUserStateContext,
// } from "../state/user";
// import { useApiTracker } from "../hooks/use-api-traker";
// import backendApi from "../api/backend";
// import { useRef } from "react";
//
// const { Text, Title } = Typography;
//
// export function LoginPageApiKey() {
//   const { setUser } = useUserStateContext();
//   const apiKeyRef = useRef(null);
//   const rememberRef = useRef(null);
//   const { status, error, trackApi } = useApiTracker();
//   const navigate = useNavigate();
//
//   const onFinish = async (values) => {
//     const storedUser = {
//       remember: rememberRef.current.input.value, //values.remember,
//       apiKey: apiKeyRef.current.input.value, //values.apiKey,
//       isLoggedIn: true,
//     };
//     trackApi(() => {
//       backendApi.setApiKey(storedUser.apiKey);
//       backendApi.userInfo().then((response) => {
//         console.log("User is admin", response.data.isAdmin);
//
//         const deviceId =
//           response.data?.authentication?.deviceId ||
//           localStorage.getItem("deviceId") ||
//           undefined;
//
//         localStorage.setItem("deviceId", deviceId);
//
//         let newUser = {
//           ...storedUser,
//           ...response.data.authentication,
//           userIdentifier: response.data.user,
//           isAdmin: response.data.isAdmin,
//           deviceId: deviceId,
//         };
//
//         setUser(newUser);
//         navigate("/");
//       });
//     });
//   };
//
//   return (
//     <BaseLayout>
//       <Row justify="center">
//         <Col span={24}>
//           <Title style={{ textAlign: "center" }}>
//             Login to get a list of your alerts
//           </Title>
//         </Col>
//       </Row>
//       <Row justify="center">
//         <Col span={24}>
//           <Card style={{ width: "384px", margin: "auto", borderRadius: 40 }}>
//             {error ? (
//               <Alert
//                 style={{ marginBottom: 24 }}
//                 type="error"
//                 message={error?.message || error}
//                 banner
//               />
//             ) : (
//               ""
//             )}
//             <Form
//               name="basic"
//               initialValues={{
//                 remember: true,
//                 isAdmin: false,
//               }}
//               onFinish={onFinish}
//               layout={"vertical"}
//               autoComplete="off"
//               style={{ marginTop: 24 }}
//             >
//               <Form.Item label="API Key" name="apiKey">
//                 <Input ref={apiKeyRef} />
//               </Form.Item>
//
//               <Form.Item name="remember" valuePropName="checked">
//                 <Checkbox ref={rememberRef}>
//                   <Text style={{ fontSize: 12 }}>
//                     This is my personal device
//                   </Text>
//                   <Text style={{ fontSize: 10 }}>
//                     <br />
//                     (This will keep you logged in for 2 weeks)
//                   </Text>
//                 </Checkbox>
//               </Form.Item>
//
//               <Form.Item
//                 wrapperCol={{
//                   offset: 8,
//                   span: 16,
//                 }}
//               >
//                 <Button
//                   type="primary"
//                   htmlType="submit"
//                   disabled={status === "loading"}
//                 >
//                   Submit
//                 </Button>
//               </Form.Item>
//             </Form>
//           </Card>
//         </Col>
//       </Row>
//     </BaseLayout>
//   );
// }
