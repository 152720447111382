import { Col, Row, Typography } from "antd";
import React from "react";
import { BaseLayout } from "../layouts/base";

const { Title, Paragraph } = Typography;

export const LegalDisclaimerPage = ({ theme }) => {
  const textClass = theme === "dark" ? "dark-mode" : "light-mode";

  return (
    <BaseLayout>
      <Row justify="center">
        <Col span={24}>
          <Title className={textClass}>Legal Disclaimer</Title>
          <Paragraph className={textClass}>
            The information provided by our website is for general informational purposes only.
            All information on the site is provided in good faith, however we make no representation
            or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity,
            reliability, availability, or completeness of any information on the site.
          </Paragraph>
          <Paragraph className={textClass}>
            Under no circumstance shall we have any liability to you for any loss or damage of any kind
            incurred as a result of the use of the site or reliance on any information provided on the site.
            Your use of the site and your reliance on any information on the site is solely at your own risk.
          </Paragraph>
          <Paragraph className={textClass}>
            By using our login page, you agree to provide your email address or phone number as your username.
            We are committed to protecting your privacy and will not share your personal information with third parties
            without your consent, except as required by law.
          </Paragraph>
          <Paragraph className={textClass}>
            This disclaimer was generated by a disclaimer generator.
          </Paragraph>
        </Col>
      </Row>
    </BaseLayout>
  );
};
