import React, { useState } from "react";
import { Modal, Button } from "antd";
import PropTypes from "prop-types";
import { TagsSelect } from "./tags_select";

export function TagsSelectModal({
  visible,
  onSave,
  onClose,
  allTags,
  selectedTags,
}) {
  const [selected, setSelected] = useState(selectedTags);

  const handleSave = () => {
    onSave(selected);
    onClose();
  };

  const handleChange = (newSelected) => {
    setSelected(newSelected);
  };

  return (
    <Modal
      title="Select Tags"
      open={visible}
      onOk={handleSave}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSave}>
          Save
        </Button>,
      ]}
    >
      <TagsSelect
        selectedTags={selected}
        allTags={allTags}
        onChange={handleChange}
        forFiltering={true}
      />
      <div
        style={{
          marginTop: "16px",
          borderTop: "1px solid #f0f0f0",
          paddingTop: "16px",
        }}
      >
        <div>
          <strong>Include Tags:</strong>{" "}
          {selected.include.length > 0 ? selected.include.join(", ") : "None"}
        </div>
        <div>
          <strong>Exclude Tags:</strong>{" "}
          {selected.exclude.length > 0 ? selected.exclude.join(", ") : "None"}
        </div>
      </div>
    </Modal>
  );
}

TagsSelectModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  allTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedTags: PropTypes.shape({
    include: PropTypes.arrayOf(PropTypes.string).isRequired,
    exclude: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};
