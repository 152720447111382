import {
  Button,
  Checkbox,
  Form,
  Input,
  Alert,
  Card,
  Row,
  Col,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import { BaseLayout } from "../layouts/base";
import { useUserStateContext } from "../state/user";
import { useApiTracker } from "../hooks/use-api-traker";
import backendApi from "../api/backend";
import { useState } from "react";

const { Text, Title } = Typography;

export function LoginPage() {
  const { setUser } = useUserStateContext();
  const { status, error, trackApi } = useApiTracker();
  const navigate = useNavigate();
  const [isSmsMode, setIsSmsMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const onFinish = async (values) => {
    setUser({ remember: values.remember, userIdentifier: values.identifier });
    const response = await trackApi(async () => {
      const response = await new Promise((resolve, reject) => {
        if (!window.grecaptcha.enterprise) {
          resolve({ status: 400, message: "reCAPTCHA not loaded" });
          return;
        }
        if (!window.grecaptcha.enterprise.execute) {
          resolve({ status: 400, message: "reCAPTCHA token not available" });
          return;
        }
        window.grecaptcha.enterprise.ready(async () => {
          try {
            const token = await window.grecaptcha.enterprise.execute(
              process.env.REACT_APP_RECAPTCHA_SECRET,
              { action: "device_verify_start" },
            );
            const response = await trackApi(() =>
              backendApi.loginStart(values.identifier, token, !isSmsMode),
            );
            resolve(response);
          } catch (error) {
            reject(error);
          }
        });
      });
      return response;
    });

    switch (response.status) {
      case 200:
        // This was an email verification with for a new or existing user.
        navigate(
          `/verify/${response.data.verificationSessionId}?prompt=Enter the code from the emai you received`,
        );
        break;
      case 201:
        // We will allow the user to login but prompt for email after logging in and then we can verify email
        navigate(
          `/verify/${response.data.verificationSessionId}?prompt=Enter the code from the text message you received&promptAfter=true`,
        );
        break;
      case 202:
        // This was an SMS verification for a new user but we had a verified email and we sent the code to the associated email.
        navigate(
          `/verify/${response.data.verificationSessionId}?prompt=You requested an SMS verification but we sent a verification code to your email instead.`,
        );
        break;
      case 422:
        setErrorMessage("Please use an email if you are a new user.");
        break;
      default:
        setErrorMessage("An error occurred. Please try again.");
    }
  };

  return (
    <BaseLayout>
      <Row justify="center">
        <Col span={24}>
          <Title style={{ textAlign: "center" }}>
            Login to get a list of your alerts
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24}>
          <Card style={{ width: "384px", margin: "auto", borderRadius: 40 }}>
            {errorMessage && (
              <Alert
                style={{ marginBottom: 24 }}
                type="error"
                message={errorMessage}
                banner
              />
            )}
            {/* <Text>Enter your phone number or email to login</Text> */}
            <Form
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              layout={"vertical"}
              autoComplete="off"
              style={{ marginTop: 24 }}
            >
              <Form.Item
                label={
                  !isSmsMode ? "Email" : "Phone Number (including country code)"
                }
                name="identifier"
                rules={[
                  {
                    required: true,
                    message: `Please enter your ${!isSmsMode ? "email" : "phone number"} to verify your account!`,
                  },
                  ...(!isSmsMode
                    ? [
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                      ]
                    : [{}]),
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="isSmsMode" valuePropName="checked">
                <Checkbox onChange={(e) => setIsSmsMode(e.target.checked)}>
                  <Text style={{ fontSize: 12 }}>
                    Use SMS (existing users only)
                  </Text>
                </Checkbox>
              </Form.Item>

              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>
                  <Text style={{ fontSize: 12 }}>
                    This is my personal device
                  </Text>
                  <Text style={{ fontSize: 10 }}>
                    <br />
                    (This will keep you logged in for 2 weeks)
                  </Text>
                </Checkbox>
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={status === "loading"}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </BaseLayout>
  );
}
