import {
  Col,
  Layout,
  Typography,
  Row,
  Switch,
  theme,
  Grid,
  Avatar,
  Dropdown,
  Badge,
} from "antd";
import { UserOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Content, Footer, Header } from "antd/es/layout/layout";
import Link from "antd/es/typography/Link";
import { useThemeStateContext } from "../state/theme";
import { useUserStateContext } from "../state/user";
import backendApi from "../api/backend";

const { useToken } = theme;
const { Text } = Typography;
const { useBreakpoint } = Grid;

export const BaseLayout = ({ children }) => {
  const { md, lg, xl, xxl } = useBreakpoint();
  const { user, setUser } = useUserStateContext();
  const { theme, setTheme } = useThemeStateContext();
  const { token } = useToken();
  const navigate = useNavigate();
  console.log("user", user);

  const isSmall = !xxl && !xl && !lg && !md;
  const padding = isSmall ? "10px" : "50px";

  // Logout Handler
  const logoutHandler = async () => {
    backendApi.deviceExpire();
    setUser({});
    backendApi.setApiKey(null);
  };

  const confirmEmailHandler = () => {
    return navigate(`/prompt-email`);
  };

  const items = [
    {
      label: (
        <Text style={{ color: "grey" }}>
          Logged in as {user?.userIdentifier}
        </Text>
      ),
      key: "1",
    },
    user?.isEmailConfirmationNeeded && {
      label: (
        <Link type="link" onClick={confirmEmailHandler}>
          Confirm Email <ExclamationCircleOutlined style={{ color: "red" }} />
        </Link>
      ),
      key: "2",
    },
    {
      label: (
        <Link type="link" onClick={logoutHandler}>
          Logout
        </Link>
      ),
      key: "3",
    },
  ].filter(Boolean); // Filter out false values

  return (
    <Layout style={{ minHeight: "100%", height: "100%" }}>
      <Header
        style={{
          background: token?.colorBgHeader,
          paddingLeft: padding,
          paddingRight: padding,
        }}
      >
        <Row className="site-header-content" align="middle">
          <Col span={isSmall ? 8 : 11}>
            <Link href="/">
              <img src="/logo.png" alt="See Turtles" height="50px" />
            </Link>
          </Col>
          <Col span={2} justify="center">
            <Switch
              checkedChildren={"Dark"}
              unCheckedChildren={"Light"}
              checked={!!theme?.isDark}
              onChange={(checked) =>
                setTheme({ ...theme, isDark: checked ? true : false })
              }
            />
          </Col>
          {user?.isLoggedIn && (
            <Col
              span={isSmall ? 14 : 11}
              style={{ textAlign: "right", lineHeight: 0 }}
            >
              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
              >
                <Badge
                  count={
                    user?.isEmailConfirmationNeeded ? (
                      <ExclamationCircleOutlined style={{ color: "red" }} />
                    ) : (
                      0
                    )
                  }
                  offset={[10, 0]}
                >
                  <Avatar
                    style={{ cursor: "pointer" }}
                    icon={<UserOutlined />}
                  />
                </Badge>
              </Dropdown>
            </Col>
          )}
        </Row>
      </Header>
      <Content
        style={{
          width: "100%",
        }}
        justify="center"
      >
        <div
          className="site-layout-content"
          style={{
            minHeight: "100%",
            padding: padding,
          }}
        >
          {children}
        </div>
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        See Turtles ©2023 |{" "}
        <Link href="/legal-disclaimer">Legal Disclaimer</Link>
      </Footer>
    </Layout>
  );
};
