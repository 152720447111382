import { Button, Form, Input, Alert, Card, Row, Col, Typography } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { BaseLayout } from "../layouts/base";
import { useUserStateContext } from "../state/user";
import { useApiTracker } from "../hooks/use-api-traker";
import backendApi from "../api/backend";
import { useState } from "react";

const { Text, Title } = Typography;

export function PromptEmailPage() {
  const { user } = useUserStateContext();
  const { status, error, trackApi } = useApiTracker();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);

  const onFinish = async (values) => {
    // Set the API key in the headers
    // backendApi.setApiKey(localStorage.getItem("apiKey") || sessionStorage.getItem("apiKey"));
    const response = await trackApi(async () => {
      const response = await new Promise((resolve, reject) => {
        if (!window.grecaptcha.enterprise) {
          resolve({ status: 400, message: "reCAPTCHA not loaded" });
          return;
        }
        if (!window.grecaptcha.enterprise.execute) {
          resolve({ status: 400, message: "reCAPTCHA token not available" });
          return;
        }
        window.grecaptcha.enterprise.ready(async () => {
          try {
            const token = await window.grecaptcha.enterprise.execute(
              process.env.REACT_APP_RECAPTCHA_SECRET,
              { action: "device_verify_start" },
            );
            const response = await trackApi(() =>
              backendApi.loginStart(values.email, token, true, true),
            );
            resolve(response);
          } catch (error) {
            reject(error);
          }
        });
      });
      return response;
    });

    if (response.status === 200) {
      navigate(`/verify/${response.data.verificationSessionId}`);
    } else {
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  return (
    <BaseLayout>
      <Row justify="center">
        <Col span={24}>
          <Title style={{ textAlign: "center" }}>
            Please confirm your email address
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24}>
          <Card style={{ width: "384px", margin: "auto", borderRadius: 40 }}>
            <div style={{ textAlign: "left" }}>
              Please enter your email address. After submitting your email
              address, you will receive a verification code. After entering the
              verification code, your email address will be confirmed.
            </div>
            {errorMessage && (
              <Alert
                style={{ marginBottom: 24 }}
                type="error"
                message={errorMessage}
                banner
              />
            )}
            <Form
              name="basic"
              onFinish={onFinish}
              layout={"vertical"}
              autoComplete="off"
              style={{ marginTop: 24 }}
              initialValues={{ email: user?.unverifiedEmail }}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message:
                      "Please enter your email to receive a verification code!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 4,
                  span: 16,
                }}
              >
                <Row gutter={16}>
                  <Col span={12} textAlign="center">
                    <Button onClick={() => navigate("/")}>Go to alerts</Button>
                  </Col>
                  <Col span={12} textAlign="center">
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={status === "loading"}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </BaseLayout>
  );
}
