export const config = {
  detailsEdit: {
    reviewFilterForCountRegex: /^reviewed-.*$/,
  },
  apiServer: {
    baseUrl:
      process.env.REACT_APP_SHELL_ALERT_API_BASE_URL ||
      "https://seeturtlesapi.azurefd.net",
    pages: {
      loginStart:
        process.env.REACT_APP_DEVICE_VERIFY_START_PAGE_OVERRIDE ||
        "/device-verify-start",
      loginVerify: "/device-verify-confirm",
      userInfo: "/user-info",
      myAlerts: "/alerts-paginator",
      adminAlerts: "/alerts-paginator-for-admins",
      deviceExpire: "/device-expire",
      downloadAdminAlerts: "/alerts-download-for-admins",
      downloadAlerts: "/alerts-download",
      updateAlert: "/alerts",
      tagList: "/tag-list",
    },
  },
  filters: {
    visibilityDropdown: [
      { label: "All", value: "all" },
      { label: "Public", value: "public" },
      { label: "Not Public", value: "not-public" },
    ],
    categoryDropdown: [
      { label: "Fake", value: "fake" },
      { label: "Real", value: "real" },
      { label: "Inconclusive", value: "inconclusive" },
      { label: "All", value: "all" },
    ],
    defaultTagsFilter: {
      include: [],
      exclude: ["oth-training-mode"],
    },
  },
  edit: {
    publicDropdown: [
      { label: "Public result", value: true },
      { label: "Non-Public result", value: false },
    ],
  },
  text: {
    header: {
      modelMatchesCategory: "Cat.",
      alertTimestamp: "When",
      nearestCity: "Nearby",
      alertUser: "User",
      // includeInPublicResults: "Pub.",
      includeInPublicResults: ({ xxl, xl, lg, md, sm, xs }) =>
        xl || xxl
          ? "Public"
          : lg
            ? "Pub."
            : md
              ? "P."
              : sm
                ? "P."
                : xs
                  ? "P."
                  : "Pub.",
      reviewCount: ({ xxl, xl, lg, md, sm, xs }) =>
        xl || xxl
          ? "Reviews"
          : lg
            ? "Rev."
            : md
              ? "R."
              : sm
                ? "R."
                : xs
                  ? "R."
                  : "Reviews",
    },
    list: {
      details: {
        hide: "Hide Details",
        show: "Show Details",
        hideSmall: "Hide",
        showSmall: "Show",
      },
      category: {
        real: "Real",
        fake: "Fake",
        inconclusive: "Inc.",
      },
    },
  },
  colors: {
    rowFakeBg: "#76b947ff",
    rowRealBg: "#b1d8b7ff",
    rowInconclusiveBg: "#94c973ff",
  },
  colWidths: {
    adminList: {
      small: {
        category: 2,
        alertTimestamp: 4,
        nearestCity: 9,
        alertUser: 7,
        reviewCount: 1,
        includeInPublicResults: 1,
      },
      default: {
        category: 3,
        alertTimestamp: 6,
        nearestCity: 5,
        alertUser: 5,
        includeInPublicResults: 1,
        reviewCount: 1,
        showDetails: 3,
      },
    },
    nonAdminList: {
      small: {
        category: 4,
        alertTimestamp: 4,
        nearestCity: 9,
        showDetails: 7,
      },
      default: {
        category: 4,
        alertTimestamp: 6,
        nearestCity: 9,
        showDetails: 5,
      },
    },
  },
};
