import axios from "axios";
import { config } from "../conf";
import { clearStateAndGoToRoot } from "../state/user";

// This class will be used by the pages to make requests to the backend
class BackendApi {
  axios;
  apiKey;
  cache = {
    tagList: {
      prom: null,
      lastUpdated: null,
    },
  };
  tagResponseCache;

  static getInstance() {
    return new BackendApi();
  }

  constructor() {
    this.axios = axios.create({
      baseURL: config.apiServer.baseUrl,
      headers: { "content-type": "application/json" },
    });
  }

  async request(requestOptions, skipAuthCheck = false) {
    let response;
    try {
      console.log("requestOptions", requestOptions);
      response = await this.axios.request({
        ...requestOptions,
        headers: { apikey: this.apiKey },
      });
    } catch (error) {
      console.log("request error", error?.response?.status);
      if (!skipAuthCheck && error.response?.status === 401) {
        try {
          const userInfo = await this.userInfo();
          if (
            !userInfo?.data?.isAdmin &&
            window.location.pathname.endsWith("/alerts-admin")
          ) {
            // Navigate to root (Which will redirect to appropriate page)
            window.location.href = "/";
          }
        } catch (userInfoError) {
          console.log("Error getting user info", userInfoError.response.status);
          if (userInfoError?.response?.status === 401) {
            // Clear State
            clearStateAndGoToRoot();
          }
          throw userInfoError;
        }
      }
      throw error;
    }
    return response;
  }

  // This method will be used to make a request to the backend to start the login process
  // It will return a promise that will resolve to the response from the backend
  // @param identifier - the phone number or email to send the login code to
  // @param captchaToken - the captcha token
  // @param isEmailMode - boolean indicating if the identifier is an email
  // @return Promise
  async loginStart(
    identifier,
    captchaToken,
    isEmailMode = false,
    confirmOnly = false,
  ) {
    const target = isEmailMode ? identifier : identifier.replace(/[^0-9]/g, "");
    const verificationType = isEmailMode ? "email" : "sms";

    return this.request({
      method: "post",
      url: config.apiServer.pages.loginStart,
      data: JSON.stringify({
        verificationType,
        target,
        captchaToken,
        confirmOnly,
      }),
    });
  }

  // loginVerify
  // This method will be used to make a request to the backend to verify the login code
  // @param deviceId - the device id
  // @param code - the code to verify
  // @return Promise
  async loginVerify(
    verificationSessionId,
    verificationCode,
    deviceId = undefined,
  ) {
    let response = null;
    try {
      response = await this.request({
        method: "post",
        url: config.apiServer.pages.loginVerify,
        data: JSON.stringify({
          verificationSessionId,
          verificationCode,
          requestedKeyType: "web",
          deviceId: deviceId || localStorage.getItem("deviceId") || undefined,
        }),
      });
      localStorage.setItem("deviceId", response.data.deviceId);
    } catch (error) {
      throw error;
    }
    return response;
  }

  // userInfo
  // This method will be used to get user info
  // @return Promise
  async userInfo() {
    return this.request(
      {
        method: "get",
        url: config.apiServer.pages.userInfo,
      },
      true,
    );
  }

  // myAlerts
  // This method will be used to make a request to the backend to get the user's alerts
  // @return Promise
  async myAlerts(filter) {
    return this.request({
      method: "get",
      url: config.apiServer.pages.myAlerts,
      params: { ...filter },
    });
  }

  // adminAlerts
  // This method will be used to make a request to the backend to get the admins alerts
  // @return Promise
  async adminAlerts(filter) {
    return this.request({
      method: "get",
      url: config.apiServer.pages.adminAlerts,
      params: { ...filter },
    });
  }

  // deviceExpire
  // This method will be used to make a request to the backend to expire a device (AKA Clear API Token)
  // @param deviceId - the device id
  // @return Promise
  async deviceExpire() {
    return this.request({
      method: "put",
      url: config.apiServer.pages.deviceExpire,
    });
  }

  // downloadAlerts
  // This method will be used to make a request to the backend to download alerts
  // @param filter - the filter to apply to the alerts
  // @return Promise
  async downloadAlerts(filter) {
    return await this.request({
      method: "get",
      url: config.apiServer.pages.downloadAlerts,
      params: { ...filter },
    });
  }

  // downloadAdminAlerts
  // This method will be used to make a request to the backend to download alerts
  // @param filter - the filter to apply to the alerts
  // @return Promise
  async downloadAdminAlerts(filter) {
    return await this.request({
      method: "get",
      url: config.apiServer.pages.downloadAdminAlerts,
      params: { ...filter },
    });
  }

  // updateAlert
  // This method will be used to make a request to the backend to download alerts
  // @param filter - the filter to apply to the alerts
  // @return Promise
  async updateAlert(data) {
    return await this.request({
      method: "put",
      url: config.apiServer.pages.updateAlert,
      data: JSON.stringify(data),
    });
  }

  // tagList
  // This method will be used to make a request to the backend to get the list of tags
  async tagList() {
    // return {data: ['Tag 1', 'tag 2', 'some other tag'], status: 200};

    // Clear cache after 30 seconds
    if (this.cache.tagList.lastUpdated + 30000 < Date.now()) {
      this.cache.tagList.prom = null;
    }

    if (!this.cache.tagList.prom) {
      // Make request to backend
      this.cache.tagList.prom = this.request({
        method: "get",
        url: config.apiServer.pages.tagList,
      });

      this.cache.tagList.lastUpdated = Date.now();
    }

    const response = await this.cache.tagList.prom;

    return response;
  }

  // setApiKey
  // This method will be used to set the api key in the axios headers
  // @param apiKey - the api key to set
  // @return void
  setApiKey(apiKey) {
    this.apiKey = apiKey;
    this.axios.defaults.headers.common["apiKey"] = apiKey;
  }
}

const instance = new BackendApi();
export default instance;
