import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import styled from "styled-components";

const FullWidthButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

const StyledCheckIcon = styled(CheckOutlined)`
  color: #52c41a;
`;

const StyledCloseIcon = styled(CloseOutlined)`
  color: #ff4d4f;
`;

export function TagsSelect({
  selectedTags,
  allTags,
  onChange,
  gutter,
  xs,
  sm,
  md,
  lg,
  forFiltering,
}) {
  const [includeTags, setIncludeTags] = useState(selectedTags.include || []);
  const [excludeTags, setExcludeTags] = useState(selectedTags.exclude || []);

  useEffect(() => {
    if (forFiltering) {
      onChange({ include: includeTags, exclude: excludeTags });
    }
  }, [includeTags, excludeTags, forFiltering, onChange]);

  useEffect(() => {
    if (!forFiltering) {
      setIncludeTags([]);
      setExcludeTags([]);
    }
  }, [forFiltering]);

  const extraTags = Array.isArray(selectedTags)
    ? (selectedTags || []).filter((tag) => !allTags.includes(tag))
    : [
        ...(selectedTags?.include || []).filter(
          (tag) => !allTags.includes(tag),
        ),
        ...(selectedTags?.exclude || []).filter(
          (tag) => !allTags.includes(tag),
        ),
      ];
  // Combine allTags with extraTags
  const combinedTags = [...allTags, ...extraTags];

  const handleTagClick = (tag) => {
    if (forFiltering) {
      if (includeTags.includes(tag)) {
        setIncludeTags(includeTags.filter((t) => t !== tag));
        setExcludeTags([...excludeTags, tag]);
      } else if (excludeTags.includes(tag)) {
        setExcludeTags(excludeTags.filter((t) => t !== tag));
      } else {
        setIncludeTags([...includeTags, tag]);
      }
    } else {
      console.log("tttt", selectedTags);
      const newSelected = selectedTags.includes(tag)
        ? selectedTags.filter((t) => t !== tag)
        : [...selectedTags, tag];
      onChange(newSelected);
    }
  };

  const getButtonProperties = (tag) => {
    if (forFiltering) {
      if (includeTags.includes(tag)) return { type: "primary" };
      if (excludeTags.includes(tag)) return { type: "primary", danger: true };
      return { type: "default" };
    }
    console.log("tttt-01", selectedTags);
    return {
      type:
        !selectedTags.exclude && selectedTags.includes(tag)
          ? "primary"
          : "default",
    };
  };

  const getCheckboxIcon = (tag) => {
    if (forFiltering) {
      if (includeTags.includes(tag)) {
        return (
          <IconContainer>
            <StyledCheckIcon />
          </IconContainer>
        );
      }
      if (excludeTags.includes(tag)) {
        return (
          <IconContainer>
            <StyledCloseIcon />
          </IconContainer>
        );
      }
      return <IconContainer />;
    }

    return (
      <IconContainer>
        {selectedTags.includes(tag) && <StyledCheckIcon />}
      </IconContainer>
    );
  };

  return (
    <Row gutter={gutter}>
      {combinedTags.map((tag) => (
        <Col key={tag} xs={xs} sm={sm} md={md} lg={lg}>
          <FullWidthButton
            {...getButtonProperties(tag)}
            onClick={() => handleTagClick(tag)}
          >
            {tag}
            {getCheckboxIcon(tag)}
          </FullWidthButton>
        </Col>
      ))}
    </Row>
  );
}

TagsSelect.propTypes = {
  selectedTags: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({
      include: PropTypes.arrayOf(PropTypes.string).isRequired,
      exclude: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ]).isRequired,
  allTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  gutter: PropTypes.arrayOf(PropTypes.number),
  xs: PropTypes.number,
  sm: PropTypes.number,
  forFiltering: PropTypes.bool,
};

TagsSelect.defaultProps = {
  gutter: [16, 16],
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
  forFiltering: false,
};
